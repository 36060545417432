import { useFood } from '../../hooks/useFood'
import { Head } from '../../components/Head'
import { Foods } from '../../components/Foods'

export default function Pizzas() {
  const { products } = useFood()

  return (
    <>
      <Head title='Produtos' description='Itttttttts Pizza Time' />
      <Foods foods={products}></Foods>
    </>
  )
}