import { Outlet } from 'react-router-dom'

import { Container } from './styles'

import { NavbarFooter } from '../../components/NavbarFooter'
import { NavbarHeader } from '../../components/NavbarHeader'
import { Modal } from '../../components/Modal'
import { useUser } from '../../hooks/useUser'

export default function Main() {
  // isso aqui pode ser movido para dentro da modal
  const {
    modalData,
    handleModalClose,
  } = useUser()
  

  return (
    <Container>
      <NavbarHeader />
      <div className="wrapper">
        <h1> </h1>
      </div>

      <section id='main'>
        <div className="wrapper">
          <Outlet />
        </div>
      </section>

      <Modal modalData={modalData} onClose={handleModalClose} />

      <NavbarFooter />
    </Container>
  )
}