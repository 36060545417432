import styled from 'styled-components'

export const ModalSection = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  
  .modal {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    h1, p {
      color: ${({ theme }) => theme.colors.black};
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      z-index: 1;
    }

    &__box {
      position: relative;
      z-index: 2;
      width: 300px;
      max-width: calc(100% - 2rem);
      background-color: rgb(255, 255, 255);
      padding: 1rem;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0.5rem;
    }

    &__content {
      
    }

    &__header {
      position: relative;
      margin-bottom: 1rem;

      h1 {
        width: calc(100% - 65px);
        margin: 0 !important;
      }
    }

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      color: ${({ theme }) => theme.colors.gray800};
      cursor: pointer;
      font-size: 11px;
      border: 0;
      background-color: transparent;

      border: 0;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.black};
      padding: .5rem;
    }
  }
`

// @media (max-width: 720px) {
//   top: 0.5rem;
//   bottom: initial;

//   span:first-child {
//     display: none;
//   }
// }