import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`
export const Inner = styled.main`
  // background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > section {
    padding: 0rem 1rem;
  }

  h4 {
    margin: 2.5rem 0 2rem;
    font-size: 1.75rem;
    
    &:first-child {
      margin-top: 0;      
    }
  }

  .grouped {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%
  }

  .field {
    flex-grow: 1;
  }

  label {
    display: block;
    font-size: 1.25rem;
    // color: ${({ theme }) => theme.colors.black};
    margin-bottom: 0.5rem;
  }

  input[type='email'],
  input[type='number'],
  input[type='tel'],
  input[type='text'],
  select {
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border: 1px solid;
    border-radius: 4px;
    transition: all 0.3s;
    height: 42px;
    width: 100%;
    margin: 0 0 1rem;
    padding: 0.7rem;
  }  
`