import styled from 'styled-components'

export const Container = styled.main`
  width: 100%;
  max-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-areas: 'header' 'title' 'content' 'footer';
  grid-template-columns: 1fr;
  grid-template-rows: var(--header-height) var(--title-height) var(--content-height) var(--footer-height);
  grid-gap: 0px;
  
  & > .wrapper > h1 {
    padding: 0 1rem;
  }

  & > section#navbarHeader,
  & > section#navbarFooter {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: end;
  }

  & > section#navbarHeader {
    grid-area: header;
  }

  & > section#navbarFooter {
    grid-area: footer;
  }

  & > section#main {
    overflow: auto;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    grid-area: content;
    width: 100%;
    padding: 0 0 1.75rem;
    
    img.logo {
      max-width: 70px;
      max-height: 70px;
      width: 100%;
      height: 100%;
    }
  }
`