// import { useCart } from '../../hooks/useCart'
import { Container } from './style'
import { OrderTotal } from './OrderTotal'
import { useUser } from '../../hooks/useUser'
import { useLocation } from 'react-router-dom'

export function OrderAddress() {
  const location = useLocation();

  const {
    getRef,
    goToIdentify,
  } = useUser()

  const handleSubmit = () => {
    if (location.pathname === '/cart') {
      goToIdentify()
    }

    console.log('handleSubmit getRef', getRef());
    getRef()?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  return (
    <Container>
      <div className="wrapper">
        <OrderTotal />

        <button type='button' onClick={handleSubmit}>Continuar</button>
      </div>
    </Container>
  )
}