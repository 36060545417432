import { useCart } from '../../../hooks/useCart'
import { currencyFormats } from '../../../helpers/currencyFormats'

import plusImg from '../../../assets/add.svg'
import minusImg from '../../../assets/minus.svg'
import deleteIcon from '../../../assets/trash.svg'

import { Container } from './styles'
import { OrderAddress } from '../../../components/Order'

export function Table() {

  const { cart, delFoodFromCart, foodCartDecrement, foodCartIncrement } = useCart()

  return (
    <Container>
      {cart.length === 0 && (
        <p>Nenhum item adicionado ao carrinho</p>
      )}

      {cart.length ? (
        <ol className='mainContent'>
          {cart.map((item) => (
            <li key={`${item.Food}-${item.id}`}>
              <div className="productBox">
                <img
                  src={item.Food.image.sourceUrl}
                  srcSet={item.Food.image.srcSet}
                  alt={item.title}
                />

                <div>
                  <h4>{item.title}</h4>
                  <span className='price'>{currencyFormats(item.subtotal)}</span>
                </div>

                <div className='cartActions'>
                  {item.quantity === 1 && (
                    <button
                      type='button'
                      onClick={() => delFoodFromCart(item)}
                    >
                      <img src={deleteIcon} alt='Reduzir quantidade' />
                    </button>
                  )}

                  {item.quantity > 1 && (
                    <button
                      type='button'
                      onClick={() => foodCartDecrement(item)}
                    >
                      <img src={minusImg} alt='Reduzir quantidade' />
                    </button>
                  )}

                  <span>{`${item.quantity}`.padStart(2, '0')}</span>

                  <button
                    type='button'
                    onClick={() => foodCartIncrement(item)}
                  >
                    <img src={plusImg} alt='Aumentar quantidade' />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ol>
      ) : null}

      <OrderAddress />
    </Container>
  )
}