import { Head } from '../../../components/Head'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Form } from '../styles'
import { schema } from './schema'
import type { FieldFormValues } from './schema'
import { OrderAddress } from '../../../components/Order'
import { useEffect, useRef } from 'react'
import { useUser } from '../../../hooks/useUser'
import InputMask from 'react-input-mask';

export default function Payment() {
  const {
    register ,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldFormValues>({
    resolver: yupResolver(schema),
  })

  const { userWithAddress, userWithPayment, updateUser, setRef } = useUser()
  const onSubmit: SubmitHandler<FieldFormValues> = (data, event) => {
    event?.preventDefault();
    const user = { ...userWithAddress, ...data };
    updateUser(user, 'payment')
  }

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      setRef(formRef.current);
      return;
    }
  }, [formRef, setRef]);

  return (
    <Container>
      <Head title='Pagamento' />

      <Form className='mainContent' onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <section>
          <div className={"field " + (errors.creditCardNumber ? 'error' : '')}>
            <label htmlFor='creditCardNumber'>Número do cartão</label>
            <InputMask
              mask="9999 9999 9999 9999"
              type='text'
              id='creditCardNumber'
              autoComplete='creditCardNumber'
              placeholder='9999 9999 9999 9999'
              defaultValue={userWithPayment.creditCardNumber}
              {...register('creditCardNumber')}
            />
            {errors.creditCardNumber && <p className='error'>{errors.creditCardNumber.message}</p>}
          </div>

          <div className={"field " + (errors.creditCardHolder ? 'error' : '')}>
            <label htmlFor='creditCardHolder'>Nome impresso</label>
            <input
              type='text'
              id='creditCardHolder'
              autoComplete='creditCardHolder'
              placeholder='JOAO SILVA'
              defaultValue={userWithPayment.creditCardHolder}
              {...register('creditCardHolder')}
            />
            {errors.creditCardHolder && <p className='error'>{errors.creditCardHolder.message}</p>}
          </div>

          <div className='grouped'>
            <div className={"field " + (errors.creditCardExpiration ? 'error' : '')}>
              <label htmlFor='creditCardExpiration'>Validade (MM/AA)</label>
              <InputMask
                mask="99/99"
                type='text'
                id='creditCardExpiration'
                autoComplete='creditCardExpiration'
                placeholder='04/12'
                defaultValue={userWithPayment.creditCardExpiration}
                {...register('creditCardExpiration')}
              />
              {errors.creditCardExpiration && <p className='error'>{errors.creditCardExpiration.message}</p>}
            </div>

            <div className={"field " + (errors.creditCardSecurityCode ? 'error' : '')}>
              <label htmlFor='creditCardSecurityCode'>CVV</label>
              <InputMask
                mask="999"
                type='text'
                id='creditCardSecurityCode'
                autoComplete='creditCardSecurityCode'
                placeholder='123'
                defaultValue={userWithPayment.creditCardSecurityCode}
                {...register('creditCardSecurityCode')}
              />
              {errors.creditCardSecurityCode && <p className='error'>{errors.creditCardSecurityCode.message}</p>}
            </div>
          </div>
        </section>

        <OrderAddress />
      </Form>
    </Container>
  )
}