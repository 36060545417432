/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useCart } from '../hooks/useCart';
import { toast } from 'react-toastify';
import { Food } from './CartContext';

// import { toast } from 'react-toastify'
// import { ProductData } from '../interfaces/ProductData'

// interface Food extends ProductData {
//   quantity: number
//   subtotal: number
// }

type CartItem = {
  id: string;
  name: string;
  price: number;
  quantity: number;
};

interface UserData {
  firstName: string
  lastName: string
  email: string
  document: string
  mobile: string
  cep: string
  street: string
  number: string
  complement: string
  city: string
  neighborhood: string
  state: string
  creditCardNumber: string
  creditCardHolder: string
  creditCardExpiration: string
  creditCardSecurityCode: string
}

interface User {
  firstName: string
  lastName: string
  email: string
  document: string
  mobile: string
}

interface UserWithAddress extends User {
  cep: string
  street: string
  number: string
  complement: string
  city: string
  neighborhood: string
  state: string
}

interface UserWithPayment extends UserWithAddress {
  creditCardNumber: string
  creditCardHolder: string
  creditCardExpiration: string
  creditCardSecurityCode: string
}

interface Checkout {
  cart?: CartItem[];
  customer?: {
    id: string;
    fullName: string;
    email: string;
    mobile: string;
    document: string;
    zipCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
  },
  payment?: {
    creditCardNumber: string;
    creditCardHolder: string;
    creditCardExpiration: string;
    creditCardSecurityCode: string;
  };
};

type UserContextType = User | UserWithAddress | UserWithPayment;

interface UserContextProps {
  user: UserContextType,
  userWithAddress: UserWithAddress,
  userWithPayment: UserWithPayment,
  modalData: any,
  updateUser: (updatedUser: UserContextType, context: string) => void,
  setRef: (newRef: HTMLFormElement) => void,
  getRef: () => HTMLFormElement | null,
  goToIdentify: () => void,
  handleModalClose: () => void,
}

interface UserProviderProps {
  children: ReactNode
}

type ModalData = {
  cart: Food[];
  customer: UserContextType;
  transactionId: any;
  paymentStatus: any;
};

export const UserContext = createContext({} as UserContextProps)

const localStorageUserKey = '@DeliveryTime:user'

export function UserProvider({ children }: UserProviderProps) {
  const navigate = useNavigate()
  const [user, setUser] = useState<UserContextType>(() => {
    const getLs = (localStorage.getItem(localStorageUserKey))
    if (getLs) return JSON.parse(getLs)
    return {
      firstName: '',
      lastName: '',
      email: '',
      document: '',
      mobile: ''
    }
  })

  const [checkout, setCheckout] = useState<Checkout>();
  const [modalData, setModalData] = useState<ModalData | undefined>(undefined);
  
  const { cart, clearCart } = useCart()

  useEffect(() => {
    goToNextStep();
  }, [])

  useEffect(() => {
    if (user) {
      goToNextStep(user);
    }
  }, [user])

  useEffect(() => {
    if (checkout) {
      orderPay();
    }
  }, [checkout])

  let formReference = useRef<HTMLFormElement | null>(null)

  function setRef(newRef: HTMLFormElement | null) {
    formReference.current = newRef;
  }

  function getRef() {
    return formReference?.current;
  }

  async function saveLs(user: UserContextType, context: string) {
    switch (context) {
      case 'address':
        setUser(user as UserWithAddress)
        break;
      case 'payment':
        setUser(user as UserWithPayment)
        break;
      default:
        setUser(user as User)
        break;
    }
    console.log('user', user)
    localStorage.setItem(localStorageUserKey, JSON.stringify(user))
  }

  async function updateUser(updatedUser: User, context: string = '') {
    try {
      let newUser: UserContextType;

      switch (context) {
        case 'address':
          newUser = {
            ...user,
            ...updatedUser
          } as UserWithAddress
          break;
        case 'payment':
          newUser = {
            ...user,
            ...updatedUser
          } as UserWithPayment
          break;
        default:
          newUser = {
            ...user,
            ...updatedUser
          } as User
          break;
      }

      await saveLs(newUser, context);
    } catch (error) {
      console.error(error)
    }
  }

  function goToNextStep(updatedUser?: UserContextType) {
    const currentPath = window.location.pathname;

    console.log('updatedUser', updatedUser)

    if (!updatedUser) {
      navigate('/')
      return;
    }

    console.log('chamou goToNextStep')

    switch (currentPath) {
      case '/identify':
        navigate('/address')
        break;
      case '/address':
        navigate('/payment')
        break;
      case '/payment':
        handleDataToPayment();
        break;
      default:
        navigate('/')
        break;
    }
  }

  function goToIdentify() {
    if (cart.length > 0) {
      navigate('/identify')
      return;
    }

    toast.error('Adicione um produto ao carrinho para continuar')
  }

  function goToHome() {
    navigate('/')
  }

  function handleDataToPayment() {
    try {
      const userData = user as UserData

      const checkoutCart = cart.map((produto) => {
        return {
          id: produto.id,
          name: produto.title,
          price: produto.Food.price,
          quantity: produto.quantity
        }
      });

      if (checkoutCart.length < 1) {
        throw new Error('Nenhum item adicionado ao carrinho')
      } 

      const data = {
        cart: checkoutCart,
        customer: {
          id: '1',
          fullName: `${userData.firstName} ${userData.lastName}`,
          email: userData.email,
          mobile: userData.mobile,
          document: userData.document,
          zipCode: userData.cep,
          street: userData.street,
          number: userData.number,
          complement: userData.complement,
          neighborhood: userData.neighborhood,
          city: userData.city,
          state: userData.state,
        },
        payment: {
          creditCardNumber: userData.creditCardNumber,
          creditCardHolder: userData.creditCardHolder,
          creditCardExpiration: userData.creditCardExpiration,
          creditCardSecurityCode: userData.creditCardSecurityCode,
        }
      }
      setCheckout(data)
    } catch (error) {
      console.error(error)
      navigate('/')
    }
  }

  async function orderPay() {
    try {
      const requestData = await axios
        .post(`https://deliverytime-be.onrender.com/checkout/`, checkout)
        .then((response) => {
          if (response.status !== 200) {
            console.log('message', response.data?.message)
            throw new Error(response.data?.message)
          }

          return response.data;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });

      if (requestData?.status !== 200) {
        throw new Error(requestData?.message)
      }

      setModalData({
        cart: cart,
        customer: user,
        transactionId: requestData.transactionId,
        paymentStatus: requestData.paymentStatus
      })
    } catch (error: any) {
      console.error(error)

      let parsedError = JSON.parse(error?.request?.response)
      if (parsedError?.error[0]?.description) {
        toast.error(parsedError?.error[0]?.description)
      }
    }
  }

  function handleModalClose() {
    clearCart();
    goToHome();
    setModalData(undefined);
  }

  return (
    <UserContext.Provider
      value={{
        user: user as User,
        userWithAddress: user as UserWithAddress,
        userWithPayment: user as UserWithPayment,
        modalData,
        updateUser,
        setRef,
        getRef,
        goToIdentify,
        handleModalClose,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}