import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.footer`
  height: var(--totalAmount-height);
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  // background: #f3f5f7;
  background: #363636;

  position: fixed;
  width: 100%;
  bottom: 50px;
  left: 0;

  // border-top: 1px solid hsl(220, 12%, 95%);
  box-shadow: inset 0 2px 2px 0 hsla(220, 0%, 95%, .2);

  button {
    background: ${({ theme }) => theme.colors.teal};
    background: #097bc3;
    width: 100%;
    padding: 0.75rem 1.5rem;
    border: 0 none !important;
    border-radius: 8px;
    // font-weight: 600;
    letter-spacing: 0.05rem;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.3s;

    &:hover {
      background: ${darken(0.1, '#097bc3')};
    }
  }

  span {
    font-weight: 300;
    position: relative;
    display: inline-block;
    width: 50%;
    margin-bottom: 1rem;

    strong {
      display: block;
      text-align: right;
      font-size: 1rem;
      font-weight: 500;
    }

    .fade-in {
      font-size: 2.25rem;
      opacity: 0;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      animation: fadeIn 1s forwards;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > section {
    padding: 0rem 1rem;
  }

  h4 {
    margin: 2.5rem 0 2rem;
    font-size: 1.75rem;
    
    &:first-child {
      margin-top: 0;      
    }
  }

  .grouped {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%
  }

  .field {
    flex-grow: 1;
  }

  label {
    display: block;
    font-size: 1.25rem;
    // color: ${({ theme }) => theme.colors.black};
    margin-bottom: 0.5rem;
  }

  input[type='email'],
  input[type='number'],
  input[type='tel'],
  input[type='text'],
  select {
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border: 1px solid;
    border-radius: 4px;
    transition: all 0.3s;
    height: 42px;
    width: 100%;
    margin: 0 0 1rem;
    padding: 0.7rem;
  }  
`
