import { currencyFormats } from '../../helpers/currencyFormats';
import { ProductData } from '../../interfaces/ProductData';
import { useCart } from '../../hooks/useCart';
import { SkeletonFood } from './SkeletonFood';
import { ReactComponent as IconPlus } from '../../assets/add.svg';

import { Container } from './styles';

interface FoodsProps {
  foods: ProductData[]
}

export function Foods({ foods }: FoodsProps) {
  const { cart, addFoodIntoCart } = useCart()

  if (!foods.length) {
    return (
      <Container>
        {[1, 2].map((n) => <SkeletonFood key={n} />)}
      </Container>
    )
  }

  return (
    <Container>
      {foods.map((product) => {
        const productExistent = cart.find(
          (item) => item.Food === product.Food && item.id === product.id,
        )
        return (
          <div key={product.id} className='food'>
            {productExistent && <span>{productExistent.quantity}</span>}

            <img src={product.Food.image.sourceUrl} srcSet={product.Food.image.srcSet} alt={product.title} />

            <div className="food__intel">
              <h2>{product.title}</h2>

              <p>{product.Food.description}</p>

              <strong>{currencyFormats(product.Food.price)}</strong>
            </div>

            <button type='button' onClick={() => addFoodIntoCart(product)}>
                <IconPlus />
              </button>
          </div>
        )
      })}
    </Container>
  )
}