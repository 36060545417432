import { useEffect, useState } from 'react'
import { ModalSection } from './styles'
import { useLocation } from 'react-router-dom';

interface ModalProps {
  modalData: any
  onClose: () => void
}

export function Modal({
  modalData,
  onClose = () => { },
}: ModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    if (!modalData) {
      return;
    }

    if (modalData.paymentStatus === "CONFIRMED") {
      setTitle('Sucesso!')
      setContent('Seu pedido foi realizado com sucesso!')
      setIsOpen(true)
      return;
    }
    
    setTitle('Oops!')
    setContent('Parece que tivemos algum problema!')
    setIsOpen(true)
  }, [modalData])


  if (!isOpen) {
    return null
  }

  if (location.pathname !== '/payment') {
    modalData = undefined;
    return null
  }

  function closeModal() {
    setIsOpen(false)
    onClose()
  }

  return (
    <ModalSection>
      <section className="modal">
        <div className="modal__overlay"></div>

        <div className="modal__box">
          <div className="modal__header">
            <button onClick={closeModal} className="modal__close">Fechar</button>
            
            <h1>{title}</h1>
          </div>
          
          <div className="modal__content">
            <p>{content}</p>
          </div>
        </div>
      </section>
    </ModalSection>
  )
}