import { Head } from '../../../components/Head'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUser } from '../../../hooks/useUser'
import { Container, Form } from '../styles'
import { schema } from './schema'
import type { FieldFormValues } from './schema'
import { OrderAddress } from '../../../components/Order'
import { useEffect, useRef } from 'react'
import InputMask from 'react-input-mask';

export default function Identify() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldFormValues>({
    resolver: yupResolver(schema),
  })

  const { user, updateUser, setRef } = useUser()
  const onSubmit: SubmitHandler<FieldFormValues> = (data, event) => {
    event?.preventDefault();
    updateUser(data, 'user')
  }

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      setRef(formRef.current);
      return;
    }
  }, [formRef, setRef]);

  return (
    <Container>
      <Head title='Identificação' />

      <Form className='mainContent' onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <section>
          <div className={"field " + (errors.firstName ? 'error' : '')}>
            <label htmlFor='firstName'>Nome</label>
            <input
              type='text'
              id='firstName'
              autoComplete='name'
              placeholder='Nome'
              defaultValue={user.firstName}
              {...register('firstName')}
            />
            {errors.firstName && <p className='error'>{errors.firstName.message}</p>}
          </div>

          <div className={"field " + (errors.lastName ? 'error' : '')}>
            <label htmlFor='lastName'>Sobrenome</label>
            <input
              type='text'
              id='lastName'
              autoComplete='lastName'
              placeholder='Sobrenome'
              defaultValue={user.lastName}
              {...register('lastName')}
            />
            {errors.lastName && <p className='error'>{errors.lastName.message}</p>}
          </div>

          <div className={"field " + (errors.email ? 'error' : '')}>
            <label htmlFor='email'>E-mail</label>            
            <input
              type='email'
              id='email'
              autoComplete='email'
              placeholder='seunome@example.com'
              defaultValue={user.email}
              {...register('email')}
            />
            {errors.email && <p className='error'>{errors.email.message}</p>}
          </div>

          <div className={"field " + (errors.document ? 'error' : '')}>
            <label htmlFor='document'>CPF</label>
            <InputMask
              mask="999.999.999-99"
              type='text'
              id='document'
              autoComplete='document'
              placeholder='999.999.999-99'
              defaultValue={user.document}
              {...register('document')}
            />
            {errors.document && <p className='error'>{errors.document.message}</p>}
          </div>

          <div className={"field " + (errors.mobile ? 'error' : '')}>
            <label htmlFor='mobile'>Celular</label>
            <InputMask
              mask="(99) 99999-9999"
              type='tel'
              id='mobile'
              autoComplete='mobile'
              placeholder='(99) 99999-9999'
              defaultValue={user.mobile}
              {...register('mobile')}
            />
            {errors.mobile && <p className='error'>{errors.mobile.message}</p>}
          </div>
        </section>

        <OrderAddress />
      </Form>
    </Container>
  )
}