import { Head } from '../../../components/Head'

import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Container, Form } from '../styles'
import { schema } from './schema'
import type { FieldFormValues } from './schema'
import { useEffect, useRef } from 'react'
import { useUser } from '../../../hooks/useUser'
import { OrderAddress } from '../../../components/Order'
import InputMask from 'react-input-mask';

export default function Address() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldFormValues>({
    resolver: yupResolver(schema),
  })

  const { user, userWithAddress, updateUser, setRef } = useUser()
  const onSubmit: SubmitHandler<FieldFormValues> = (data, event) => {
    event?.preventDefault();
    updateUser({ ...user, ...data }, 'address')
  }

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      setRef(formRef.current);
      return;
    }
  }, [formRef, setRef]);

  return (
    <Container>
      <Head title='Endereço' />

      <Form className='mainContent' onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <section>
          <div className={"field " + (errors.cep ? 'error' : '')}>
            <label htmlFor='cep'>CEP</label>
            <InputMask
              mask="99999-999"
              type='text'
              id='cep'
              autoComplete='cep'
              placeholder='99999-999'
              defaultValue={userWithAddress.cep}
              {...register('cep')}
            />
            {errors.cep && <p className='error'>{errors.cep.message}</p>}
          </div>

          <div className={"field " + (errors.street ? 'error' : '')}>
            <label htmlFor='street'>Endereço</label>
            <input
              type='text'
              id='street'
              autoComplete='street'
              placeholder='Rua Gomes Carneiro'
              defaultValue={userWithAddress.street}
              {...register('street')}
            />
            {errors.street && <p className='error'>{errors.street.message}</p>}
          </div>

          <div className='grouped'>
            <div className={"field " + (errors.number ? 'error' : '')}>
              <label htmlFor='number'>Número</label>
              <input
                type='text'
                id='number'
                autoComplete='number'
                placeholder='123'
                defaultValue={userWithAddress.number}
                {...register('number')}
              />
              {errors.number && <p className='error'>{errors.number.message}</p>}
            </div>

            <div className={"field " + (errors.complement ? 'error' : '')}>
              <label htmlFor='complement'>Complemento</label>
              <input
                type='text'
                id='complement'
                autoComplete='complement'
                placeholder='Apto 101'
                defaultValue={userWithAddress.complement}
                {...register('complement')}
              />
              {errors.complement && <p className='error'>{errors.complement.message}</p>}
            </div>
          </div>

          <div className='grouped'>
            <div className={"field " + (errors.city ? 'error' : '')}>
              <label htmlFor='city'>Cidade</label>
              <input
                type='text'
                id='city'
                autoComplete='city'
                placeholder='Pelotas'
                defaultValue={userWithAddress.city}
                {...register('city')}
              />
              {errors.city && <p className='error'>{errors.city.message}</p>}
            </div>

            <div className={"field "}>
              <label htmlFor='state'>Estado</label>
              <select
                id='state'
                defaultValue={userWithAddress.state}
                {...register('state')}
              >
                <option value=''>Selecione</option>
                <option value='AC'>Acre</option>
                <option value='AL'>Alagoas</option>
                <option value='AP'>Amapá</option>
                <option value='AM'>Amazonas</option>
                <option value='BA'>Bahia</option>
                <option value='CE'>Ceará</option>
                <option value='ES'>Espírito Santo</option>
                <option value='GO'>Goiás</option>
                <option value='MA'>Maranhão</option>
                <option value='MT'>Mato Grosso</option>
                <option value='MS'>Mato Grosso do Sul</option>
                <option value='MG'>Minas Gerais</option>
                <option value='PA'>Pará</option>
                <option value='PB'>Paraíba</option>
                <option value='PR'>Paraná</option>
                <option value='PE'>Pernambuco</option>
                <option value='PI'>Piauí</option>
                <option value='RJ'>Rio de Janeiro</option>
                <option value='RN'>Rio Grande do Norte</option>
                <option value='RS'>Rio Grande do Sul</option>
                <option value='RO'>Rondônia</option>
                <option value='RR'>Roraima</option>
                <option value='SC'>Santa Catarina</option>
                <option value='SP'>São Paulo</option>
                <option value='SE'>Sergipe</option>
                <option value='TO'>Tocantins</option>
                <option value='DF'>Distrito Federal</option>
              </select>
            </div>
          </div>

          <div className={"field " + (errors.neighborhood ? 'error' : '')}>
            <label htmlFor='neighborhood'>Bairro</label>
            <input
              type='text'
              id='neighborhood'
              autoComplete='neighborhood'
              placeholder='Centro'
              defaultValue={userWithAddress.neighborhood}
              {...register('neighborhood')}
            />
            {errors.neighborhood && <p className='error'>{errors.neighborhood.message}</p>}
          </div>
        </section>

        <OrderAddress />
      </Form>
    </Container>
  )
}