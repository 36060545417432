import { Routes, Route } from 'react-router-dom'

import MainPage from './pages/Main'
import ProductsPage from './pages/Products'
// import PizzasPage from './pages/Pizzas'
// import DrinksPage from './pages/Bebidas'

import Cart from './pages/Cart'
import Payment from './pages/Steps/Payment'
import Identify from './pages/Steps/Identify'
import Address from './pages/Steps/Address'

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={<MainPage />}>
        <Route path='/' element={<ProductsPage />} />
        {/* <Route path='/pizzas' element={<PizzasPage />} /> */}
        {/* <Route path='/bebidas' element={<DrinksPage />} /> */}
        <Route path='/cart' element={<Cart />} />
        <Route path='/identify' element={<Identify />} />
        <Route path='/address' element={<Address />} />
        <Route path='/payment' element={<Payment />} />
      </Route>
    </Routes>
  )
}
