import { Container } from "./styles"
import axios from 'axios'
import { useQuery } from "react-query";
import { SettingData } from "../../interfaces/SettingData";
import { useEffect, useState } from "react";

const endpoint = "https://deliverycms.afumagalli.com.br/graphql";
const headers = {
  "content-type": "application/json",
  "Authorization": "<token>"
}

const SETTINGS_QUERY = `
  {
    setupPages {
      nodes {
        title
        Setup {
          logo {
            sourceUrl(size: LARGE)
          }
          endereco
          horarioDeEncerramento
          horarioDeFuncionamento
          name
          banner {
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;

export function NavbarHeader() {
  const [settings, setSettings] = useState<SettingData>({} as SettingData)

  const { data, isLoading, error } = useQuery("settings", () => {
    return axios({
      url: endpoint,
      method: "POST",
      headers: headers,
      data: {
        query: SETTINGS_QUERY
      }
    }).then(response => response.data.data.setupPages.nodes[0].Setup);
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error) {
      return;
    }

    if (!data) {
      return
    }
    setSettings(data)
  }, [data, error, isLoading])

  // console.log('data', data);

  if (isLoading) {
    return <></>
  }

  return (
    <Container id='navbarHeader'>
      <div className='bgImg'>
        <img src={settings?.banner?.sourceUrl} alt="Imagem de fundo para dar contraste com o logo da loja" />
      </div>

      <section>
        <div className="wrapper">
          <div className="nav">
            <div className="clientAwaitTime">
              <p>
                {settings.horarioDeFuncionamento} às {settings.horarioDeEncerramento}
              </p>
            </div>

            <div className='clientLogo'>
              <img className='logo' src={settings?.logo?.sourceUrl} alt="logo tipo da loja" />
            </div>

            <div className="clientStatus">
              <p>{settings.endereco}</p>
            </div>
          </div>
        </div>
      </section>

      <section id="clientCategory">
        <p>Sessão com as categorias dos produtos aqui (?)</p>
        <p>Talvez tenha que ser um componente separado para funcionar na estrutura</p>
      </section>
    </Container>
  )
}