import * as yup from "yup";

export type FieldFormValues = yup.InferType<typeof schema>

export const schema = yup
  .object({
    creditCardNumber: yup.string().required('O preenchimento do número do cartão é obrigatório'),
    creditCardHolder: yup.string().required('O preenchimento do nome do titular é obrigatório'),
    creditCardExpiration: yup.string().required('O preenchimento da data de validade é obrigatório'),
    creditCardSecurityCode: yup.string().required('O preenchimento do código de segurança é obrigatório'),
  })
  .required();
