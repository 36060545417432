import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --header-height: 120px;
    --title-height: 77px;
    --content-height: calc(100vh - var(--header-height) - var(--footer-height) - var(--title-height));
    --footer-height: 50px;
    --totalAmount-height: 104px;
    // var(--header-height)
    // var(--title-height)
    // var(--content-height)
    // var(--footer-height)
    // var(--totalAmount-height)
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.75%;
    }
  }

  body {
    background: ${({ theme }) => theme.colors.gray800};
    -webkit-font-smoothing: antialiased;
    color: ${({ theme }) => theme.colors.black} 
  }
  
  body,
  input,
  select,
  textarea,
  button {
    font: 400 1rem 'Montserrat', Helvetica, Arial, sans-serif;
  }

  .mainContent {
    overflow: auto;
    height: 100%;
    max-height: calc(var(--content-height) - var(--totalAmount-height));
  }
  
  a {
    color: inherit;
    text-decoration: none;    
  }

  button {
    cursor: pointer;
  }

  ul,
  li {
    list-style: none;
  }

  .wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1024px;
  }

  h1 {
    font-weight: 400;
  }

  p, span, a, button, strong, h1, h2, h3, h4, h5, h6, label, input, select, textarea {
    color: ${({ theme }) => theme.colors.white};
  }

  form {
    .field.error {
      input {
        border-color: ${({ theme }) => theme.colors.red};
      }

      p {
        color: ${({ theme }) => theme.colors.red};
        margin-bottom: 1rem;
      }
    }
  }
`;
