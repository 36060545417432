import { ReactNode, createContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useQuery } from "react-query";
import { ProductData } from '../interfaces/ProductData'

const endpoint = "https://deliverycms.afumagalli.com.br/graphql";
const headers = {
  "content-type": "application/json",
  "Authorization": "<token>"
}

const PRODUCTS_QUERY = `
  {
    products (where: {orderby: {field: MENU_ORDER, order: ASC}}) {
      nodes {
        id
        title
        Food {
          description
          price
          foodType {
            ... on Tipo {
              title
            }
          }
          image {
            id
            srcSet
            sourceUrl
          }
        }
      }
    }
  }
`;

interface FoodContextProps {
  products: ProductData[]
  // pizzas: ProductData[]
  // bebidas: ProductData[]
}

interface FoodProviderProps {
  children: ReactNode;
}

export const FoodContext = createContext({} as FoodContextProps)

export function FoodProvider({ children }: FoodProviderProps ) {
  // const [pizzas, setPizzas] = useState<ProductData[]>([]) // Provavelmente vai sair
  // const [bebidas, setBebidas] = useState<ProductData[]>([]) // Provavelmente vai sair
  const [products, setProducts] = useState<ProductData[]>([])

  const { data, isLoading, error } = useQuery("products", () => {
    return axios({
      url: endpoint,
      method: "POST",
      headers: headers,
      data: {
        query: PRODUCTS_QUERY
      }
    }).then(response => response.data.data.products.nodes as ProductData[]);
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }
  
    if (error) {
      return;
    }

    if (!data) {
      return
    }
    setProducts(data)
  }, [data, error, isLoading])

  return (
    <FoodContext.Provider value={{ products }}>
      {children}
    </FoodContext.Provider>
  )
}

