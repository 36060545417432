import * as yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'

export type FieldFormValues = yup.InferType<typeof schema>

export const schema = yup
  .object({
    firstName: yup.string().required('Este campo é obrigatório.!'),
    lastName: yup.string().required('Este campo é obrigatório.!'),
    email: yup
      .string()
      .email()
      .required('O preenchimento de e-mail é obrigatório'),
    document: yup
      .string()
      .required('O preenchimento do CPF é obrigatório')
      .test('cpf', 'CPF inválido', value => {
        return cpf.isValid(value)
      }),
    mobile: yup.string().required('Você deve informar um telefone'),
  })
  .required()
