import { useCart } from '../../../hooks/useCart'
import { currencyFormats } from '../../../helpers/currencyFormats'
import { Fragment } from 'react'
// import { Container } from '../style'

export function OrderTotal() {
  const { cart } = useCart()

  const totalAmount = cart.reduce((acc, item) => (acc += Number(item.subtotal)), 0)

  return (
    <Fragment>
      <span>
        Total:
      </span>
      <span>
        <strong>{currencyFormats(totalAmount)}</strong>
      </span>
    </Fragment>
  )
}