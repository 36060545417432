interface HeadProps {
  title: string
  description?: string
}

export function Head({ title, description = '' }: HeadProps) {
  document.title = `Delivery Time | ${title}`
  document.querySelector('[name=description]')?.setAttribute('content', description)
  const h1Element = document.querySelector('h1');
  if (h1Element) {
    h1Element.innerText = title || '';
  }

  return null
}