import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-template-columns: 1fr;
  gap: 1.75rem;
  padding: 0 1rem;

  // @media (max-width: 500 px) {
  //   grid-template-columns: 1fr;
  // }

  .food {
    display: grid;
    grid-template-columns: 100px 1fr calc(35px + 0.75rem);
    grid-template-rows: 1fr;
    position: relative;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    box-shadow: inset 0 2px 6px 0 #333;
    overflow: hidden;
    gap: 0.75rem;

    span, p, h2, strong {
      color: ${({ theme }) => theme.colors.gray800};
    }

    // decidir se esse cara vai realmente existir aqui
    span {
      position: absolute;
      // top: -0.5rem;
      // left: -0.5rem;
      top: 0.5rem;
      left: 0.5rem;
      box-shadow: 0 2px 6px 0 ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
      background ${({ theme }) => theme.colors.red};
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 1.125rem;
    }

    img {
      display: block;
      object-fit: cover;
      // object-fit: fill;
      object-position: center center;
      width: 100%;
      height: 100%;
    }

    &__intel {
      padding: 0.75rem 0;
      overflow: hidden;

      h2 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.75rem;
        color: rgba(29, 30, 33, 0.9);
      }

      p {
        font-size: 1.25rem;
        margin-bottom: 0.75rem;
        font-weight: 400;
        color: rgba(29, 30, 33, 0.8);
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        width: 100%;
      }

      strong {
        letter-spacing: 0.5px;
        font-size: 1.25rem;
        // color: ${({ theme }) => theme.colors.black};
        font-weight: 400;
      }
    }

    button {
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      border-radius: 50%;
      padding: 0.75rem;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 25px;
        height: 25px;
        path {
          stroke: ${({ theme }) => darken(0.3, theme.colors.green)};
        }
      }

      &:hover {
        svg {
          path {
            stroke: ${({ theme }) => darken(0.2, theme.colors.green)};
          }
        }
      }
    }
  }
`;
