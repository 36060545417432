import * as yup from "yup";

export type FieldFormValues = yup.InferType<typeof schema>

export const schema = yup
  .object({
    cep: yup.string().required('O preenchimento do CEP é obrigatório'),
    street: yup.string().required('O preenchimento do endereço é obrigatório'),
    number: yup.string().required('O preenchimento do número é obrigatório'),
    complement: yup.string().required('O preenchimento do complemento é obrigatório'),
    city: yup.string().required('O preenchimento da cidade é obrigatório'),
    neighborhood: yup.string().required('O preenchimento do bairro é obrigatório'),
    state: yup.string().required('O preenchimento do estado é obrigatório'),
  })
  .required();
