import { createContext, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { toast } from 'react-toastify'

import { ProductData } from '../interfaces/ProductData'

export interface Food extends ProductData {
  quantity: number
  subtotal: number
}

interface CartContextProps {
  cart: Food[]
  addFoodIntoCart: (food: ProductData) => void
  delFoodFromCart: (food: Food) => void
  foodCartIncrement: (food: Food) => void
  foodCartDecrement: (food: Food) => void
  orderConfirm: () => void
  orderAddress: () => void
  orderIdentify: () => void
  oderPay: () => void
  clearCart: () => void
}

interface CartProviderProps {
  children: ReactNode
}

export const CartContext = createContext({} as CartContextProps)

const localStorageKey = '@DeliveryTime:cart'

export function CartProvider({ children }: CartProviderProps) {
  const navigate = useNavigate()
  const [cart, setCart] = useState<Food[]>(() => {
    const getLs = (localStorage.getItem(localStorageKey))
    if (getLs) return JSON.parse(getLs)
    return []
  })

  function saveLs(items: Food[]) {
    setCart(items)
    localStorage.setItem(localStorageKey, JSON.stringify(items))
  }

  function clearLs() {
    localStorage.removeItem(localStorageKey)
  }

  function clearCart() {
    setCart([])
    clearLs()
  }

  function addFoodIntoCart(food: ProductData): void {
    const foodExistentInCart = cart.find(
      (item) => item.Food === food.Food && item.id === food.id,
    )

    if (foodExistentInCart) {
      const newCart = cart.map((item) => {
        if (item.id === food.id) {
          const quantity = item.quantity + 1
          const subtotal = item.Food.price * quantity

          return { ...item, quantity, subtotal }
        }

        return item
      })

      toast.success(`${food.Food.foodType.title} ${food.title} adicionado aos pedidos!`)
      saveLs(newCart)

      return
    }

    const newFood = { ...food, quantity: 1, subtotal: food.Food.price }
    const newCart = [...cart, newFood]

    toast.success(`${food.Food.foodType.title} ${food.title} adicionado aos pedidos!`)
    saveLs(newCart)
  }

  function delFoodFromCart(food: Food) {
    const newCart = cart.filter((item) => !(item.id === food.id && item.Food === food.Food))

    saveLs(newCart)
  }

  function updateFoodQuantity(food: Food, newQuantity: number) {
    if (newQuantity <= 0) return

    const foodExistsInCart = cart.find(
      (item) => item.id === food.id && item.Food === food.Food,
    )

    if (!foodExistsInCart) return

    const newCart = cart.map((item) => {
      if (item.id === foodExistsInCart.id && item.Food === foodExistsInCart.Food) {
        return { ...item, quantity: newQuantity, subtotal: item.Food.price * newQuantity }
      }

      return item
    })

    saveLs(newCart)
  }

  function foodCartIncrement(food: Food) {
    updateFoodQuantity(food, food.quantity + 1)
  }

  function foodCartDecrement(food: Food) {
    updateFoodQuantity(food, food.quantity - 1)
  }

  function orderConfirm() {
    navigate('/payment')
  }

  function orderAddress() {
    navigate('/address')
  }

  function orderIdentify() {
    navigate('/identify')
  }

  function oderPay() {
    console.log()
    clearLs()
    return
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addFoodIntoCart,
        delFoodFromCart,
        foodCartIncrement,
        foodCartDecrement,
        orderConfirm,
        orderAddress,
        orderIdentify,
        oderPay,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}